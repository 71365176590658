$background-color-transition: background-color 0.4s linear !important;
.info-item {
  svg {
    margin-right: 15px;
  }
  &:hover {
    color: #000;
  }
}
.brand {
  user-select: none;
  font-size: 30px;
  font-weight: 700;
  color: #ff2c2c;
}
.dropdown-info {
  font-size: 1rem;
  font-weight: 500;
  user-select: none;
  color: #fff;
  &:hover {
    color: #ff4b00;
  }
}
.dropdown-item {
  left: 380px !important;
  ul {
    li {
      font-size: 14px;
    }
    background-color: #270d0d !important;
  }
}
.transparent {
  ul {
    background-color: #000000 !important;
  }
}

.link {
  color: #fff !important;
  text-decoration: none !important;
  &::marker {
    background-color: #fff;
  }
  & > a {
    display: inline-block;
    position: relative;

    justify-content: center;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    transition: all 0.5s ease-in-out;

    &::before {
      content: attr(data-item);
      transition: 0.5s;
      color: #b98ae4;
      position: absolute;
      left: 7px;
      top: 7px;
      bottom: 0;
      right: 0;
      width: 0;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover {
      color: #fff;
      transform: scale(1.08);
      &::before {
        width: 100%;
        transition: all 0.5s ease-in-out;
      }
    }

    &:focus {
      color: #fff;
    }
  }
}

.appbar-expanded {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap !important;

  .user-dropdown {
    margin-left: 20px;
    margin-right: 20px;
  }

  .nav-right {
    display: flex;
    width: 70%;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
  }

  .nav-left {
    width: 20%;
    justify-content: flex-start;
    margin-left: 20px;
    align-self: center;
    align-items: center;
  }

  .search-field {
    margin-right: 30px;
    font-size: 10px;
    width: 300px;
    height: auto;
    & > div > div {
      height: 20px;
    }
    & > div {
      margin-top: 0 !important;
    }
  }
  .appbar-brand {
    & > img {
      width: 170px;
    }
    align-self: center;
  }

  .nav-link.nav-element {
    @extend .link;
  }

  .login-collapse {
    a {
      &::before {
        left: 14px !important;
      }
    }
    margin-top: auto;
    margin-bottom: auto;
  }
}

.collapse-logo {
  position: absolute;
  max-width: 100px;
  right: 10vw;
  display: none;
}

.appbar-toggler {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.5rem;
  border: none;
}

.appbar {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1360px;
    max-height: 60px;
    margin-right: auto;
    margin-left: auto;
  }

  &.appbar-transparent {
    transition: $background-color-transition;
    background: transparent !important;
  }

  &.bg-info {
    transition: $background-color-transition;
    background-image: linear-gradient(192deg, #000000 0%, #cb2c29 100%);
  }
}

.appbar-collapse-header {
  display: none;
}

.collapsed {
  .link {
    @extend .link;
    cursor: pointer;
    margin-top: 3rem;
    & > a {
      &::before {
        left: 0px;
        top: 0px;
      }
    }
  }
}

.custom-btn {
  button {
    font-size: 1rem;
    font-weight: 500;
    user-select: none;
    border-radius: 5px;
  }
  .login {
    color: #fff;
    border: 1px solid #139b34;
    margin-left: 5px;
    min-width: 160px;
    background-color: #139b34;
    padding: 0 20px;
  }
  .signup {
    color: #fff;
    border: 1px solid #ff3c00;
    margin-left: 5px;
    min-width: 160px;
    background-color: #ff3c00;
    padding: 0 20px;
  }
  .deposit {
    border: 1px solid #0aac53;
    margin-left: 5px;
    min-width: 115px;
    background-color: #0aac53;
  }
  .cashout {
    border: 1px solid #817e80;
    min-width: 115px;
    background-color: #817e80;
  }
  color: #fff;
  text-align: center;
  line-height: 35px;
  outline: none;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

@media screen and (max-width: 556px) {
  .appbar {
    padding: unset !important;
    &.bg-info {
      background-image: linear-gradient(
        270deg,
        #000000 0%,
        #2a282b 100%
      ) !important;
    }
    &.appbar-transparent {
      background-image: linear-gradient(
        270deg,
        #000000 0%,
        #2a282b 100%
      ) !important;
    }
    .appbar-brand {
      img {
        width: 120px !important;
      }
      width: 25% !important;
    }
  }

  .custom-btn {
    div {
      font-size: 14px;
    }

    margin-top: auto;
    margin-bottom: auto;
    padding: unset;
    line-height: 30px;
  }
  .nav-link.nav-element {
    .signup {
      margin-left: unset;
      min-width: 100px;
    }
    a {
      font-size: 17px !important;
    }
  }
  .appbar-toggler-container {
    display: flex;
    width: 35%;
  }
}

@media screen and (max-width: 991px) {
  .brand {
    font-size: 15px;
  }

  .appbar-toggler-bar {
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: #fff;

    & + .appbar-toggler-bar {
      margin-top: 7px;
    }

    &.bar2 {
      width: 17px;
      transition: width 0.2s linear;
    }
  }

  .appbar {
    .container {
      min-height: 60px;
    }

    padding-top: 10px;
    padding-bottom: 0px;
    &.bg-info {
      background-image: linear-gradient(
        270deg,
        #000000 0%,
        #2a282b 100%
      ) !important;
    }
    &.appbar-transparent {
      background-image: linear-gradient(
        270deg,
        #000000 0%,
        #2a282b 100%
      ) !important;
    }
    .appbar-brand {
      width: 20%;
    }

    .appbar-collapse {
      &.collapse-hide {
        animation: hide_navbar_collapse 1.5s ease forwards;
      }

      animation: show_navbar_collapse 0.7s ease forwards;
      .link {
        .content {
          margin-left: 20px;
        }

        display: flex;
        padding: 20px 10px;
        align-items: center;
        border-bottom: 1px solid #303030;
        position: relative;
        cursor: pointer;
        margin-top: 10px;
      }
      .appbar-collapse-header {
        display: block;
        padding-bottom: 1rem;

        .collapse-brand {
          font-size: 20px;
          color: #fff;
          a {
            font-weight: 500;
          }
        }

        .collapse-close {
          margin: auto;
          &:hover {
            opacity: 50%;
          }
          svg {
            color: wheat;
          }
          button {
            border-color: red !important;
            border: none;
            padding: none !important;
          }
        }
      }
    }
  }

  .appbar-collapse {
    background-size: 100% 100%;
    min-height: 500px;
    min-width: 370px;

    align-items: center;
    position: absolute !important;
    height: auto !important;
    left: 0;
    top: 60px;
    background: #2d060f;
    border-radius: 0.2857rem;
    padding: 1.4rem;
  }
}

@keyframes show_navbar_collapse {
  0% {
    transform: translateX(-1000px);
    transform-origin: 100% 0;
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes hide_navbar_collapse {
  0% {
    transform: translateX(0px);
    transform-origin: 100% 0;
  }

  100% {
    transform: translateX(-1000px);
  }
}
