.brand-list{
    color: white !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 40px;
}

.brarnd-item {
    img {
        &:hover{
            opacity: 1;
        }
        transition: opacity .3s ease;
        max-width: 150px;
        max-height: 40px;
        margin: 10px;
        opacity: 0.5;
        display: block;
    }
}
