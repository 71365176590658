.container {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  width: 100%;
  max-width: 1420px;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 100px;
}

.text-red {
  color: #bd0000;
}

.content {
  .primary-header {
    font-size: 50px;
    user-select: none;
    line-height: 57px;
    color: #ff4b00;
    font-weight: 500;
    text-align: center;
    margin-bottom: unset;
  }

  .sec-header {
    margin: auto;
    user-select: none;
    margin-top: 20px;
    font-size: 40px;
    font-weight: 500;
    line-height: 57px;
    color: #fffbf9;
    text-align: center;
  }
}
.clover {
  display: flex;
  justify-content: space-between;
  & > span {
    margin-left: auto !important;
    margin-right: auto !important;
    justify-content: center;
    display: flex;
    & > img {
      width: 120px;
      opacity: 0.8;
    }
  }
}
.item {
  .learn-more {
    font-size: 15px;
    color: #b82612;
    &:hover {
      color: #942011;
    }
  }
  .header {
    text-transform: uppercase;
    font-size: 50px;
  }
  .content {
    text-transform: uppercase;
    font-size: 20px;
    padding: 10px;
    width: 75%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  span {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 1 !important;
  }
  text-align: center;
}

.body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  text-align: center;
  .header {
    width: 100%;
    color: #fff;
    font-size: 80px;
    margin-top: 10px;
    line-height: 70px;
  }
  .text {
    margin-top: 30px;
    width: 100%;

    font-size: 20px;
    font-weight: 0;
  }
  .body-sec-header {
    width: 100%;
    margin-top: 50px;
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;
    letter-spacing: 10px;
  }
}

.benefits {
  width: 100%;
  position: relative;
}

.tailor {
  left: -210px;
  top: 25px;
  position: absolute;
  max-width: 200px;
}

.fox {
  max-width: 250px;
  position: absolute;
  right: -230px;
  top: 75px;
}

.commission-title {
  margin-bottom: 0 !important;
  letter-spacing: -1px !important;
}

.commission-text {
  font-size: 20px !important;

  border-bottom: 2px solid #ff2c2c;
  padding-bottom: 20px;
}
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.signup {
  border: 1px solid #ff3c00;
  margin-left: 5px;
  max-width: 200px;
  background-color: #ff3c00;
  border-radius: 20px;
  padding: 0 20px;
  font-size: 25px !important;
  font-weight: 500 !important;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  &:active {
    cursor: pointer;
    transform: scale(1.1) translateY(4px);
  }
}

.revenue {
  margin-top: 40px;
  margin-bottom: 40px;

  font-size: 30px;

  .revenue-container {
    max-width: 800px;
    display: flex;
    margin-left: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item {
    justify-content: flex-start;
    display: flex;

    flex-direction: column;
    margin-left: 20px;
    flex-grow: 1;
    span {
      font-size: 17px;
    }
    sub {
      font-weight: 500;
      font-size: 12px;
    }
    .amount {
      font-size: 30px;
    }
  }
  .subject {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ff2c2c;
    font-size: 53px;
  }
  .amount {
    color: #ff2c2c;
  }
}

.benefit-container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .benefit {
    margin: 8px;
    padding: 30px;
    border: 0.5px groove #ff2c2c;
    text-align: left;
    width: 380px;
    .title {
      vertical-align: middle;
      font-weight: 700px;
      .main {
        font-size: 2rem;
        color: #fff;
      }
      .secondary {
        margin-left: 2px;
        font-size: 1.4rem;
        color: #ff2c2c;
        transform: scaleY(1.5);
      }
    }
    .benefit-text {
      font-size: 1.1rem;
    }
  }
}
.footer-container {
  span {
    width: 100%;
  }
  position: relative;
  display: flex;
  justify-content: center;
}

.footer-text {
  position: absolute;
  max-width: 30%;
  font-size: 20px;
  text-align: center;
  top: 120px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 768px) {
  .container {
    margin-top: 20px;
  }
  .commission-title {
    margin-top: 20px !important;
  }

  .body {
    .header {
      width: 100%;
      margin-top: 50px;
      font-weight: 700;
      font-size: 30px;
      line-height: 70px;
      letter-spacing: 10px;
    }
  }
  .revenue {
    .revenue-container {
      margin-left: 0px !important;
    }
  }

  .item {
    margin-top: 30px;
    margin-left: unset !important;
    .content {
      padding: 0px;
      width: 100%;
    }
  }
  .content {
    .primary-header {
      font-size: 35px !important;
    }
  }
  .footer-container {
    display: none;
  }
  .revenue {
    font-size: 20px;
    .item {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
    .subject {
      width: 20%;
      color: #ff2c2c;
      font-size: 23px;
    }
    .amount {
      color: #ff2c2c;
    }
  }
}
