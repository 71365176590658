/* FOOTER */

.footerbg {
  background-size: cover;
  font-weight: 500;
  padding-bottom: 20px;
}
.titleMobileAd {
  &:hover {
    color: #ff4b00;
  }
  cursor: pointer;
  padding-bottom: 20px;
  padding-top: 50px;
  line-height: 30px;
  letter-spacing: 1px;
  font-weight: bolder;
  font-size: 55px;
}
.descriptionMobileAd {
  padding-bottom: 100px;
  max-width: 570px;
  line-height: 30px;
  letter-spacing: 1px;
  font-size: 15px;
}

.footerMobileAd {
  margin-bottom: 80px;
  margin-top: 110px;
  background: linear-gradient(288.85deg, #48b2ff 26.61%, #7e34f3 74.55%);
}

.footerLogo {
  display: flex;
  justify-content: right;
  img {
    display: block;
    max-height: 450px;
    overflow: visible;
    margin-top: -530px;
  }
}

@media screen and (max-width: 991px) {
  .titleMobileAd {
    font-size: 0;
  }
  .descriptionMobileAd {
    font-size: 0;
  }
  .footerLogo {
    justify-content: center;
    img {
      display: block;
      max-height: 400px;
      overflow: visible;
      margin-top: -440px;
    }
  }
}

.rightfooter {
  height: 600px;
  position: absolute;
  right: -10vw;
}

.rightfooter img,
.leftfooter img {
  height: 100%;
}

.leftfooter {
  height: 600px;
  position: absolute;
  left: -10.4vw;
}

.footermenu {
  color: #fff;
  font-size: 1.5rem;

  li:first-child {
    color: #c39ce8;

    margin-bottom: 20px;
  }
}

.textbottomfooter {
  color: #fff;

  font-size: 1.1rem;
  text-align: justify;
  float: left;
  margin: 10px auto;
}

.itensocialfooter {
  background-size: cover;
  float: left;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  width: 60px;
  min-height: 60px;
  img {
    width: 30px;
  }
}

.footersocialmedia {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  & > span:nth-child(3) > .itensocialfooter img {
    margin-left: -6px;
  }
  & > span:last-child > .itensocialfooter img {
    width: 15px;
  }
}

/* END FOOTER */

@media screen and (max-width: 991px) {
  /* FOOTER */
  .footerbg .container {
    padding-right: 0;
    padding-left: 0;
  }

  .leftfooter,
  .rightfooter {
    display: none;
  }

  .footersocialmedia {
    margin-bottom: 30vw;
  }

  /* END FOOTER */
}
