.container {
  display: flex;
  flex-direction: row;
}
.logo {
  margin: auto;
  justify-content: center;
}
.desc {
  margin: auto;
  max-width: 70%;
}
.content {
  a {
    text-decoration: none;
  }
  color: #fff;
  font-size: 20px;
  font-style: italic;
  padding: 10px;

  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.swiper {
  margin-top: 70px;
  max-width: 960px;
}
.primary-header {
  font-size: 50px;
  user-select: none;
  line-height: 57px;
  color: #ff2c2c;
  font-weight: 500;
  text-align: center;
  margin-bottom: unset;
}
.img {
  max-width: 120px;
  max-height: 70px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .desc {
    max-width: unset;
  }
}
