.copyright {
  padding: 16px 0 52px;
  margin-top: 100px;

  font-size: 14px;
  line-height: 1.4;
  background-color: #101010;
}

.header {
  width: 100%;
  margin: 0 auto;
  padding: 0 45px;
  display: flex;
  align-items: center;
}
.text {
  align-items: center;
  display: inline;
  font-size: 14px;
  line-height: 21px;
  color: #9ea1ae;
  margin: 0;
}
.menu {
  display: flex;
  margin-left: auto;
  flex-wrap: wrap;
  .link {
    &:hover {
      text-decoration: underline !important;
    }
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    margin-left: 30px;
    cursor: pointer;
  }
}
.icons {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: center;
  img {
    margin-right: 18px;
  }
  .apg-seal-container {
    .seal {
      img {
        width: 100%;
        height: auto;
      }
      display: block;
      position: relative;
      overflow: hidden;
      max-width: 32px;
      min-width: 32px;
      background-image: url(https://9687875d-dfs-4abf-98e4-e4fefd662e89.d.antillephone.com/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png);
    }
    width: 48px;
    height: auto;
  }
}
.content {
  width: 100%;
  margin: 0 auto;
  padding: 13px 45px;
}

.support:hover {
  text-decoration: underline !important;
}

@media screen and (max-width: 776px) {
  .content {
    padding: 13px 8px;
  }
  .link {
    font-size: 10px !important;
    margin-left: 0 !important;
  }
  .header {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }
  .menu {
    margin: 0 !important;
    display: flex;
    justify-content: flex-start;
    padding-left: 0 !important;
    li {
      padding: 10px;
      justify-content: flex-start;
    }
  }
}
